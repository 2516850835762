import {Injectable, Injector} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router'
import {firstValueFrom, Observable} from 'rxjs'

@Injectable()
export class MasterGuard implements CanActivate {

  constructor(
    private injector: Injector,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const guards = route.data.guards ?? []

    for (const guard of guards) {
      const instance: CanActivate = this.injector.get(guard)

      let result = instance.canActivate(route, state)

      if (result instanceof Promise) {
        result = await result
      }

      if (result instanceof Observable) {
        result = await firstValueFrom(result)
      }

      if (result === false || result instanceof UrlTree) {
        return result
      }
    }

    return true
  }
}
