import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {catchError, of, switchMap} from 'rxjs'

import {AuthService} from '@services/http/auth.service'
import {UserService} from '@services/http/user.service'
import {AlertService} from '@services/ui/alert.service'

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private alertService: AlertService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const log = (...args: any): void => {
      const root = route.data.root
      const flag = root ? 'Root' : 'Child'
      const url = root ? route.routeConfig.path : state.url
      console.info(flag, ...args, url)
    }

    if (!this.authService.isAuthenticated) {
      log('[AuthGuard] No auth tokens found, redirecting login page from route:')

      this.router.navigateByUrl('/auth')
      return false
    }

    if (!this.userService.user) {
      log('[AuthGuard] No user data found, fetching the data for route:')

      return this.userService.getUser().pipe(
        switchMap(() => {
          log('[AuthGuard] Fetched user successfully for route:')
          return of(true)
        }),
        catchError(() => {
          log('[AuthGuard] Couldn\'t fetch user. Logging out from route:')
          this.alertService.showError('Не удалось получить данные пользователя.')
          this.authService.logout()
          return of(false)
        }),
      )
    }

    log('[AuthGuard] Has auth tokens and user data, entering route:')
    return true
  }
}
